<template>
  <v-toolbar color="grey darken-3" height="80" flat>    
      <v-row no-gutters>
        <v-col cols="10">
          <v-text-field
              v-model="search"
              class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
              dense
              filled
              flat
              hide-details
              placeholder="Buscar..."
              solo
          >
            <template slot="prepend-inner">
              <v-icon
                  class="material-icons-round mt-n2"
                  color="#adb5bd"
                  size="18px"
              >search
              </v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" class="float-right" @click="newUser()">
            Nuevo Usuario
          </v-btn>
        </v-col>
      </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: "UsersTableToolbar",
  data:() =>({
    search: null,
  }),
  methods:{
     newUser(){
       this.$emit("on-new-user", true);
     }
  }
}
</script>

<style scoped>

</style>

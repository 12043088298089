<template>
  <div>    
    <v-container class="py-6" fluid>
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <users-table ref="usersTable"></users-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UsersTable from "./Widgets/UsersTable";
import UsersNewDialog from "./Widgets/UsersNewDialog"

export default {
  name: "Users",
  components: {
    UsersTable,
    // eslint-disable-next-line vue/no-unused-components
    UsersNewDialog
  },
  data() {
    return {
    };
  },  
  methods:{
    
  }
}
</script>

<style scoped>

</style>

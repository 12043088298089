<template>
  <v-dialog
      v-model="isVisible"
      width="600"
      scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
      <v-container>
        <v-row v-if="showError">
          <v-col>
            <v-alert
                type="error"
                variant="outlined"
            >
              {{message}}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="6"
          >
            <v-text-field
                label="Nombre"
                required
                v-model="v$.model.name.$model"
                :error="v$.model.name.$error"
                :error-messages="nameErrors"
            ></v-text-field>
          </v-col>
          <v-col
              cols="6"
          >
            <v-text-field
                label="Apellidos"
                required
                v-model="v$.model.surname.$model"
                :error="v$.model.surname.$error"
                :error-messages="surnameErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="6"
          >
            <v-text-field
                label="Email"
                type="email"
                required
                v-model="v$.model.email.$model"
                :error="v$.model.email.$error"
                :error-messages="emailErrors"
            ></v-text-field>
          </v-col>
          <v-col
              cols="6"
          >
            <v-text-field
                label="Usuario"
                required
                v-model="v$.model.userName.$model"
                :error="v$.model.userName.$error"
                :error-messages="userNameErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!isEdition">
          <v-col
              cols="6"
          >
            <v-text-field
                label="Contraseña"
                type="password"
                required
                v-model="v$.model.password.$model"
                :error="v$.model.password.$error"
                :error-messages="passwordErrors"
            ></v-text-field>
          </v-col>
          <v-col
              cols="6"
          >
            <v-text-field
                label="Confirmar Contraseña"
                type="password"
                required
                v-model="v$.model.passwordConfirmation.$model"
                :error="v$.model.passwordConfirmation.$error"
                :error-messages="passwordConfirmationErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
                v-model="model.roleNames"
                label="Rol"
                :items="roles"
                multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
            color="blue-darken-1"
            text
            @click="save()"
            :loading="isSaving"
            :disabled="isLoading"
        >
          Guardar
        </v-btn>
        <v-btn
            color="blue-darken-1"
            text
            @click="close()"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, minLength, required, sameAs} from '@vuelidate/validators'
import userService from "@/services/user.service";


export default {
  name: "UsersNewDialog.vue",
  props:{
    dialog: {
      type: Boolean,
      default: false
    },
    editUserId:{
      type:String,
      default: null,
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      model: {
        name: {
          required, name_validation: {
            $validator: (value) => {
              let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
              return validNamePattern.test(value);
            },
            $message: 'Nombre inválido. El nombre válido solo contiene letras, guiones (-) y espacios'
          }
        },
        surname: {
          required, surname_validation: {
            $validator: (value) => {
              let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
              return validNamePattern.test(value);
            },
            $message: 'Invalid last name. Valid name contains only letters, hyphens (-), and spaces'
          }
        },
        email: { required, email },
        userName: { required },
        password: { required: !this.isEdition ? required: false, min: minLength(6) },
        passwordConfirmation: { required: !this.isEdition ? required: false, sameAsPassword: sameAs(this.model.password) }
      },
    }
  },
  data() {
    return {
      title: "Nuevo Usuario",
      roles: [],
      isSaving: false,
      isLoading: false,
      showError: false,
      message: "",
      model:{
        userName: "",
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        roleNames: [],
        password: "",
        passwordConfirmation: ""
      }
    };
  },
  computed:{
    isVisible(){
      return this.dialog;
    },
    isEdition(){
      return !!this.editUserId;
    },
    nameErrors(){
      return this.v$.model.name.$errors.map(err => err.$message);
    },
    surnameErrors(){
      return this.v$.model.surname.$errors.map(err => err.$message);
    },
    emailErrors(){
      return this.v$.model.email.$errors.map(err => err.$message);
    },
    userNameErrors(){
      return this.v$.model.userName.$errors.map(err => err.$message);
    },
    passwordErrors(){
      return this.v$.model.password.$errors.map(err => err.$message);
    },
    passwordConfirmationErrors(){
      return this.v$.model.passwordConfirmation.$errors.map(err => err.$message);
    }
  },
  watch:{
    dialog(val){
      if(val){
        this.title = "Nuevo Usuario";
        this.$emit("on-open", val);
        
        if(this.isEdition){
          this.title = "Editar";
          this.getUser()
        }
      }else {
        this.$emit("on-close", val);  
      }
    },
    "model.roleNames"(val){
      console.log(val)
    }
  },
  mounted() {
    this.loadRoles()
  },
  methods:{
    close(){
      this.$emit("on-close", false);
      this.clearForm()
    },
    clearForm(){
      this.model = {
              userName: "",
              name: "",
              surname: "",
              email: "",
              phoneNumber: "",
              roleNames: [],
              password: "",
              passwordConfirmation: ""
      }
    },
    loadRoles(){
      userService.getAllRoles().then(d =>{
        this.roles = d.items.map(item => item.name)
      })
    },
    getUser(){
      this.isLoading = true;
      
      userService.getUser(this.editUserId).then(d =>{
        this.model.userName = d.userName;
        this.model.name = d.name;
        this.model.surname = d.surname;
        this.model.email = d.email;
        this.model.phoneNumber = d.userName;
        
        return userService.getUserRoles(this.editUserId);        
      }).then(roles =>{
        this.model.roleNames = roles.items.map(r => r.name);
      }).finally(() =>{
        this.isLoading = false;
      })
    },
    save(){
      this.message = "";
      this.showError = false;
      
      this.v$.$validate().then(isValid =>{
        if(isValid){
          this.isSaving = true;
          
          if(this.isEdition){
            return userService.updateUser(this.editUserId, {
              userName: this.model.userName,
              name: this.model.name,
              surname: this.model.surname,
              email: this.model.email,
              isActive: true,
              roleNames: this.model.roleNames,
            })
          }else {
            return userService.createUser({
              userName: this.model.userName,
              name: this.model.name,
              surname: this.model.surname,
              email: this.model.email,
              isActive: true,
              roleNames: this.model.roleNames,
              password: this.model.password
            })
          }
        }
      }).then(d =>{
        if(d) {
          this.clearForm();
          this.$emit("on-success", {
            wasEdition: this.isEdition
          });
        }
      }).catch(d =>{
        if(d.error){
          this.message = d.error.message;
          this.showError = true;
        }
      }).finally(() =>{
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="text-center">
    <v-progress-circular
        v-if="roles.length===0"
        indeterminate
        color="primary"
    />
    <v-chip
        v-for="rol in roles"
        class="ma-2"
        label
        x-small
    >
      {{rol.name}}
    </v-chip>
  </div>
</template>

<script>

import userService from "@/services/user.service";

export default {
  name: "RolesList",
  components: {
  },
  props:{
    userId:{
      type: String,
      default: ''
    },
  },
  data: () => ({
    roles: [],
    isLoading: true
  }),
  computed:{
  },
  mounted() {
    this.getUserRoles();
  },
  watch:{
  },
  methods:{
    getUserRoles(){
      this.isLoading = true;
      userService.getUserRoles(this.userId).then(d =>{
        this.roles = d.items;
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>

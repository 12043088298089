<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <users-new-dialog :dialog="newUserDialog" :edit-user-id="editUserId" @on-close="closedUserNewDialog" @on-success="successUserNewDialog" />
    <v-card class="border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="users"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            :search="search"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <users-table-toolbar @on-new-user="showNewUserDialog" />
          </template>
          <template v-slot:item.id="{ item }">
            <div class="d-flex align-center">
              <v-checkbox
                  v-model="item.checkbox"
                  :ripple="false"
                  color="#141727"
              ></v-checkbox>
              <span class="my-2 text-xs text-body font-weight-light">{{
                  item.id
                }}</span>
            </div>
          </template>

          <template v-slot:item.roles="{ item }" class="d-flex">
            <roles-list :user-id="item.id" />
          </template>
          <template v-slot:item.actions="{ item }" class="d-flex">
            <v-icon
                small
                class="material-icons-round"
                @click="deleteItem(item)"
            >
              delete
            </v-icon>
            <v-icon
                small
                class="material-icons-round"
                @click="editItem(item)"
            >
              edit
            </v-icon>
            <v-icon
                small
                class="material-icons-round"
                @click="sendItem(item)"
            >
            {{ loading ? 'refresh' : 'phone_android' }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>            
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import userService from "@/services/user.service";

import UsersTableToolbar from "@/views/Pages/Administration/Widgets/UsersTableToolbar";
import UsersNewDialog from "@/views/Pages/Administration/Widgets/UsersNewDialog";
import RolesList from "@/views/Pages/Administration/Widgets/RolesList";

export default {
  name: "UsersTable",
  components:{
    UsersTableToolbar,
    UsersNewDialog,
    RolesList,
  },
  data() {
    return {
      editUserId: null,
      isLoading: false,
      newUserDialog: false,
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      users: [],
      search: "",
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Nombre Usuario",
          value: "userName",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Email",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Nombre",
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Apellidos",
          value: "surname",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        { text: 'Roles', value: 'roles', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  watch:{
    itemsPerPage(){
      this.getUsers();
    },
    page(){
      this.getUsers();
    }
  },
  mounted() {
    this.getUsers();
  },
  methods:{
    getUsers(){
      this.isLoading = true;
      const offset = (this.page - 1) * this.itemsPerPage;

      this.users = [];
      
      userService.getUsers(offset,this.itemsPerPage).then(d =>{
        this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
        this.users = d.items;

        this.isLoading = false;
      });
    },
    showNewUserDialog(){
      this.editUserId = null;
      this.newUserDialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    closedUserNewDialog(value){
      this.editUserId = null;
      this.newUserDialog = false;
    },
    successUserNewDialog(value){
      this.newUserDialog = false;
      if(value.wasEdition){
        this.$swal("Usuario actualizado","","success").then(() =>{
          this.getUsers();
        });
      }else{
        this.$swal("Usuario creado","","success").then(() =>{
          this.getUsers();
        });
      }
    },
    editItem(user){
      this.editUserId = user.id;
      this.newUserDialog = true;
    },
    sendItem(user){
      this.loading = true;
      this.isLoading = true;
      userService.sendLastVersion(user.id).then(d =>{
        if(d==false){
          this.$swal('Se cargo el archivo correctamente.', '', 'success');
          this.loading = false;
          this.isLoading = false;
        }else{
          this.$swal('La Ultima Versión ha sido Enviada.', '', 'success');
          this.loading = false;
          this.isLoading = false;
        }
        });
    },
    deleteItem(user){
      this.$swal({
        title: `Estas seguro de eliminar el usuario [${user.name} ${user.surname}]?`,
        text: "No es posible revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {          
          userService.deleteUser(user.id).then(() =>{
            this.getUsers();
            this.$swal('Usuario eliminado!', '', 'success');
          })
        } 
      });
    }
  }
}
</script>

<style scoped>

</style>
